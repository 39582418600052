import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import PageHeader from "../components/common/PageHeader"

import Seo from "../components/seo"
import Button from "../components/common/Button"

export default function PageTemplate({ data }) {
  const lang = data.wpPage.language.slug
  // const files = data.wpPage.utskick.filer
  const translation = data.wpPage.translations[0]?.uri
  // console.log(files)
  return (
    <div className="utskick-template">
      <Seo title={data.wpPage.title} />
      <Layout lang={lang} translation={translation}>
        <div className="space-y-8">
          <PageHeader text={data.wpPage.title} />
        </div>
        <div>
          <div className="py-8">
            <div
              dangerouslySetInnerHTML={{ __html: data.wpPage.content }}
              className="wp-content"
            />
          </div>
        </div>
        {/* {files && (
          <div>
            {files.map(download => {
              return <Download key={download.title} download={download} />
            })}
          </div>
        )} */}
      </Layout>
    </div>
  )
}

function Download({ download }) {
  return (
    <div className="flex flex-row space-x-2">
      {download.fil && (
        <div>
          <Button link={download.fil?.localFile.url}> {download.titel}</Button>
        </div>
      )}
    </div>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      language {
        slug
      }
      translations {
        uri
      }
    }
  }
`
